<template>
  <div class="inline-block">
    <ColorTextBtn class="line" @click="showDialog">设置税号 </ColorTextBtn>
    <EForm
      v-if="dialogVisible"
      ref="form"
      title="设置税号"
      :dialogVisible.sync="dialogVisible"
      :sup_this="_this"
      :is-add="true"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import { commonEheaderMixin } from '@/mixins'
import formField from './field'
import EForm from './form'

export default {
  mixins: [commonEheaderMixin],
  components: { EForm },
  props: {},
  data() {
    return {
      formField,
      _this: this
    }
  }
}
</script>

<style lang="scss"></style>
