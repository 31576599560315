<template>
  <!-- 操作 -->
  <div class="operation-component">
    <EditShop v-if="hasEidt && initData.isEnabled == 1" :initData="initData" :sup_this="sup_this"></EditShop>
    <el-divider
      v-if="
        hasEidt &&
        initData.isEnabled == 1 &&
        ((initData.isEnabled == 0 && hasEnable) || (initData.isEnabled !== 0 && hasDisable))
      "
      direction="vertical"
    ></el-divider>
    <AbleOperateStore
      v-if="initData.isEnabled == 0 && hasEnable"
      :initData="initData"
      :sup_this="sup_this"
      title="启用"
    ></AbleOperateStore>

    <AbleOperateStore
      v-if="initData.isEnabled !== 0 && hasDisable"
      title="禁用"
      :initData="initData"
      :sup_this="sup_this"
    ></AbleOperateStore>
    <el-divider v-if="hasDelete && initData.isEnabled == 0" direction="vertical"></el-divider>
    <DeleteShop v-if="hasDelete && initData.isEnabled == 0" :initData="initData" :sup_this="sup_this"></DeleteShop>
    <el-divider v-if="hasOrderList && initData.isEnabled !== 0" direction="vertical"></el-divider>
    <color-text-btn v-if="hasOrderList && initData.isEnabled !== 0" @click="linkTo(initData)">店铺订单</color-text-btn>
  </div>
</template>

<script>
import EditShop from '../editShop'
import DeleteShop from '../deleteShop'
import AbleOperateStore from '../ableOperateStore'
import UpdateParagraph from '../updateParagraph'

export default {
  components: { EditShop, AbleOperateStore, UpdateParagraph, DeleteShop },
  props: {
    initData: {
      type: Object,
      require: true
    },
    sup_this: {
      type: Object,
      default: null
    },

    hasEidt: Boolean,
    hasDisable: Boolean,
    hasEnable: Boolean,
    hasDelete: Boolean,

    hasOrderList: Boolean // 店铺订单
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    linkTo(row) {
      const id = row.id
      this.$router.push(`/user/storeOrder?id=${id}`)
    }
  }
}
</script>

<style lang="scss">
.store-operation-component-popover {
  text-align: center;
}
</style>
